.Toastify__toast--success {
    @apply alert alert-success;
}

.Toastify__toast--error {
    @apply alert alert-error;
}

.Toastify__toast--warning {
    @apply alert alert-warning;
}

.Toastify__toast--info {
    @apply alert alert-info;
}

.Toastify__toast-body {
    @apply font-bold;
}

.Toastify__progress-bar--success {
    @apply bg-green-500;
}

.Toastify__progress-bar--error {
    @apply bg-red-500;
}

.Toastify__progress-bar--warning {
    @apply bg-yellow-500;
}

.Toastify__progress-bar--info {
    @apply bg-blue-500;
}